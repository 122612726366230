import React from "react"
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import OtherMIESolutions from "../components/sections/hubs/other-MIE-hub";
import FeaturedCaseStudy from "../components/sections/case-study/featured-case-study";

import residentialIcon from "../images/other-mie/residential.png";
import studAccoIcon from "../images/other-mie/student_accom.png";
import hotelIcon from "../images/other-mie/hotels.png";
import publicWifiIcon from "../images/other-mie/public_wifi.png";

import ourOwnFibreIcon from "../images/rne/info/ownfibre_icon.svg";
import connectedLivingIcon from "../images/rne/info/connected_living_icon.svg";
import qualityIcon from "../images/rne/info/quality_icon.svg";

import SpeedTab from "../components/tabs/speed";
import EnhanceUsabilitySpaces from "../components/tabs/enhancing-usability-spaces";


import bannerImage from "../images/box-headers/buildtorent.jpg";
import caseStudyImage from "../images/btr/btr-case-study.svg";


import bgImg from "../images/headers/subheader_blue.svg";

import { getCategoryId } from "../utils/categories";

const BuildToRent = () => {
  const categoryId = getCategoryId("Build-to-Rent");

  return (
    <Layout title="Build-to-Rent">
      <SEO title="Build To Rent - Wholesale Internet - VostroNet">
        <meta name="description" content="VostroNet delivers high speed internet to Build-to-Rent developments with wholesale options for building management. Easily manage via our Q2 software platform." />
      </SEO>
      <Banner subpage bgImg={bgImg} data={{
        title: {
          name: "Build-to-Rent",
        },
        subtitles: [
          {
            name: `Simplifying Internet Delivery`,
            className: "fw-400",
          },
          {
            name: `VostroNet delivers tailored internet services to build-to-rent developments at wholesale prices. Our solution is designed to cater to users coming and going while allowing new residents to get online before they unpack.`,
            className: "banner-text-small",
          },
        ],
        img: bannerImage,
      }}
        hideScrollButton
        customButtons={(<>
          <Container>
            <Row>
              <Col xs="auto">
                <Button variant="green" className="btn-banner mb-3" target="_blank" rel="noopener" href="https://cdn.vostro.cloud/content/vostronet-datasheet-btr.pdf">
                  <i className="fas fa-file-pdf mr-2" />
                  {"Build-to-Rent Datasheet"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button variant="outline-dark" className="btn-banner-secondary" target="_blank" rel="noopener" href="https://cdn.vostro.cloud/content/vostronet-solutions-btr.pdf">
                  <i className="fas fa-binoculars mr-2" />
                  {"Quick overview"}
                </Button>
              </Col>
            </Row>
          </Container>
        </>)} />
      <div className="general py-5">
        <Container>
          <Row>
            <Col>
              <div className="title mb-3">
                Connected Living
                </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="desc my-4">
                {"VostroNet's software allows residents to be onboarded within seconds of their arrival. Fast Wi-Fi Mesh lets residents connect whenever they are in the building; so they can skype their family or 4K video while in gym, the pool or their apartment."}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <InformationSection data={{
        title: "First-class infrastructure",
        subtitle: "We are dedicated to delivering a reliable, fast, and expanding FTTx network, underpinned by world-class technology, We manage our own network infrastructure on quality equipment ensuring a great resident experience",
        subData: [
          {
            title: "Our own fibre",
            subtitle: "By owning and operating our own fibre from the data centre to the home, we can ensure performance and quality is maintained at a higher level than others.",
            img: ourOwnFibreIcon,
          },
          {
            title: "Quality Design",
            subtitle: "We invest resources to tailor our design to suit the project. We use the best equipment to minimise downtime and maximise performance.",
            img: connectedLivingIcon,
          },
          {
            title: "Connected Living",
            subtitle: "Create a true vertical community with the assistance of Wi-Fi coverage everywhere for residents. We are the experts in deploying and operating Wi-Fi.",
            img: qualityIcon,
          },
        ],
      }} />

      <Container className="py-5 tab-section">
        <Row>
          <Col>
            <Tabs defaultActiveKey="enhance" id="SA-tab-section">
              <Tab eventKey="enhance" title="Enhancing the usability of spaces">
                <EnhanceUsabilitySpaces />
              </Tab>
              <Tab eventKey="speed" title="Speed">
                <SpeedTab />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <FeaturedCaseStudy data={{
        link: "https://cdn.vostro.cloud/content/casestudy-residential-339swanston.pdf",
        title: "Featured case studies",
        desc: "High-speed wireless mesh for Melbourne CBD apartment building",
        img: caseStudyImage,
        sideNote: "Centralised Platform with speeds of 100 Mbps to each unit, even in peak internet usage times.",
      }} />
      <Blog category={categoryId} />
      <OtherMIESolutions data={[
        {
          label: "Residential MDU",
          link: "/residential-fibre-network/",
          img: residentialIcon,
        },
        {
          label: "Student Accommodation",
          link: "/student-accommodation/",
          img: studAccoIcon,
        },
        {
          label: "Hotels",
          link: "/hotel/",
          img: hotelIcon,
        },
        {
          label: "Public Wi-Fi",
          link: "/public-wifi/",
          img: publicWifiIcon,
        },
      ]} />
    </Layout>
  );
}

export default BuildToRent;
